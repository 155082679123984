import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import CustomHead from '../components/common/head/Head';
import { apiUrl } from '../config/apiConfig';
import axios from 'axios';
import { metaSiteTitle } from '../config/defaultMetaConfig';

const HeroArea = dynamic(
  () => import('../components/pages/homepage/HeroArea'),
  {
    loading: () => {
      return '';
    },
    ssr: false,
  }
);
const ClientsArea = dynamic(
  () => import('../components/pages/homepage/ClientsArea'),
  {
    loading: () => {
      return '';
    },
    ssr: false,
  }
);
const ServiceArea = dynamic(
  () => import('../components/pages/homepage/ServiceArea'),
  {
    loading: () => {
      return '';
    },
    ssr: false,
  }
);
const TestimonialArea = dynamic(
  () => import('../components/pages/homepage/TestimonialArea'),
  {
    loading: () => {
      return '';
    },
    ssr: false,
  }
);
const FeatureArea = dynamic(
  () => import('../components/pages/homepage/FeatureArea'),
  {
    loading: () => {
      return '';
    },
    ssr: false,
  }
);
const BlogArea = dynamic(
  () => import('../components/pages/homepage/BlogArea'),
  {
    loading: () => {
      return '';
    },
    ssr: false,
  }
);
const ContactUsArea = dynamic(
  () => import('../components/pages/homepage/ContactUsArea'),
  {
    loading: () => {
      return '';
    },
    ssr: false,
  }
);
const DevelopmentCompanyArea = dynamic(
  () => import('../components/pages/homepage/DevelopmentCompanyArea'),
  {
    loading: () => {
      return '';
    },
    ssr: false,
  }
);
const HomeFaq = dynamic(() => import('../components/pages/homepage/HomeFaq'), {
  loading: () => {
    return '';
  },
  ssr: false,
});
const FeatureCount = dynamic(
  () => import('../components/pages/homepage/FeatureCount'),
  {
    loading: () => {
      return '';
    },
    ssr: false,
  }
);
const HomePage = ({ siteSetting }) => {
  // console.log("siteSetting", siteSetting);

  const [developmentProcess, setdevelopmentProcess] = useState([]);
  const [homePageFaqs, sethomePageFaqs] = useState([]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      axios
        .get(apiUrl + '/api/homePageApi')
        .then((response) => {
          if (response && response.data.data && response.data.data) {
            setdevelopmentProcess(response.data.data.development_process);
            sethomePageFaqs(response.data.data.home_page_faqs);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <CustomHead title={metaSiteTitle} />
      <div className="page-area homepage-area mh-80v">
        <HeroArea siteSetting={siteSetting} />
        <ServiceArea />
        <TestimonialArea />
        <FeatureArea />

        <DevelopmentCompanyArea
          siteSetting={siteSetting}
          developmentProcess={developmentProcess}
        />
        <FeatureCount />
        <BlogArea />
        <ClientsArea />
        <ContactUsArea siteSetting={siteSetting} />
        <HomeFaq homePageFaqs={homePageFaqs} />
      </div>
    </>
  );
};

export async function getServerSideProps() {
  try {
    const siteSetting = await axios
      .get(apiUrl + '/api/siteSetting')
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return null;
      });

    return {
      props: {
        siteSetting: siteSetting,
        loaded: true,
      },
    };
  } catch (error) {
    return {
      props: {
        siteSetting: null,
        loaded: true,
      },
    };
  }
}

export default HomePage;
