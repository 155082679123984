const siteTitle = 'Update Tech Ltd';
const siteTagLine = 'Software company in Bangladesh';

const metaSiteTitle = siteTagLine + " | " + siteTitle;

const metaDesc = "Update Tech Ltd is a leading Business Process Outsourcing (BPO) and software development company in bangladesh.";

const metaKeywords = "Update Tech Ltd, BPO, Software company, Bangladesh, Outsourcing, development, PHP, Laravel, Node Js, React Js, Next Js, Python";

module.exports = {
  siteTitle,
  siteTagLine,
  metaSiteTitle,
  metaDesc,
  metaKeywords
}